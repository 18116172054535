import React from "react";
import { useNavigate } from "react-router-dom";
import data from "../data/services.json";

const colors = ["bg-blue-500", "bg-green-500", "bg-red-500", "bg-yellow-500", "bg-purple-500", "bg-pink-500"];

const CardList = () => {
  const navigate = useNavigate();

  return (
    <div className="p-6">
      {/* Section Title */}
      <div className="section-title text-center mb-8">
        <h2 className="relative text-4xl font-extrabold text-gray-800 dark:text-white inline-block">
          <span className="absolute inset-x-0 -bottom-1 h-1 bg-blue-500 rounded-full"></span>
          Services
        </h2>
        <p className="text-gray-600 dark:text-white mt-2 text-lg">
          What we do offer
        </p>
      </div>

      {/* Cards Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {data.map((item, index) => (
          <div
            key={item.id}
            className="flex flex-col bg-white shadow-md rounded-lg overflow-hidden cursor-pointer transition-transform transform hover:scale-105"
            onClick={() => item.link && navigate(item.link)}
          >
            {/* Colored Top Section with Title */}
            <div className={`${colors[index % colors.length]} text-white text-center py-2 font-bold text-lg`}>
              {item.title}
            </div>

            {/* Card Content */}
            <div className="flex items-center p-4">
              <img src={item.imgUrl} alt={item.title} className="w-24 h-24 object-contain p-2" />
              <div className="pl-4">
                <ul className="text-md font-semibold text-gray-600 space-y-1">
                  {item.description.map((desc, index) => (
                    <li key={index} className="text-gray-600">• {desc}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardList;
