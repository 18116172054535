import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const Events = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

    // Function to format the date
    const formatDate = (dateString) => {
      const date = new Date(dateString);
  
      const options = {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      };
  
      return date.toLocaleString('en-GB', options)
        .replace(',', '')
        .replace('AM', 'A.M.')
        .replace('PM', 'P.M.');
    };

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get('https://hellolocal.in/api.php?action=get_events');
        if (response.data.status === 'success') {
          setEvents(response.data.events);
        } else {
          setError('Failed to fetch events.');
        }
      } catch (err) {
        setError('An error occurred while fetching data.');
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  if (loading) {
    return <div>Loading events...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
      <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-8 border-b-4 border-green-500 inline-block">Upcoming Events</h1>
      <div className="flex flex-col gap-12 mt-8">
        {events.map((event, index) => (
          <StyledWrapper key={index}>
            <div className="card">
              <div className="image-section">
                <img src={event.event_img} alt={event.etitle} />
              </div>
              <div className="content-section">
                <div className="title">
                  <span>
                    <img
                      src="https://hellolocal.in/react/event.svg"
                      alt=""
                      height={25}
                      width={25}
                    />
                  </span>
                  <p className="title-text">{event.etitle}</p>
                </div>
                <p className="detail">
                  <strong>Venue:</strong> {event.event_venue}, {event.event_loc}
                </p>                
                <p className="detail">
                  <strong>Date & Time:</strong> {formatDate(event.event_start)} - {formatDate(event.event_end)}
                </p>
                <div className="detail">
                  <strong>Event Details:</strong>
                  <div
                    dangerouslySetInnerHTML={{ __html: event.edescription }}
                  />
                </div>
              </div>
            </div>
          </StyledWrapper>
        ))}
      </div>
    </div>
  );
};

const StyledWrapper = styled.div`
  .card {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 10px 10px #323232;
    border: 3px solid #323232;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s ease;
  }

  @media(min-width: 768px) {
    .card {
      flex-direction: row;
    }
  }

  .card:hover {
    transform: translateY(-10px);
  }

  .image-section {
    width: 100%;
  }

  @media(min-width: 768px) {
    .image-section {
      width: 40%;      
    }
  }

  .image-section img {
    width: 100%;
    height : 100%;
    object-fit: cover;
  }

  .content-section {
    width: 100%;
    padding: 1.5rem;
  }

  @media(min-width: 768px) {
    .content-section {
      width: 60%;
    }
  }

  .title {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  .title span {
    background-color:rgb(200, 236, 224);
    padding: 0.75rem;
    border-radius: 9999px;
  }

  .title-text {
    margin-left: 1rem;
    margin-top: 1rem;
    font-size: 1.3rem;
    font-weight: 700;
  }

  .detail {
    font-size: 1.1rem;
    color: #555;
    margin-bottom: 0.5rem;
  }

  .description-list ul {
    margin-left: 1.25rem;
    list-style-type: disc;
    color: #333;
  }
`;

export default Events;
