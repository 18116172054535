import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DOMPurify from 'dompurify';

const News = () => {
  const [news, setNews] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get('https://hellolocal.in/api.php?action=get_news');
        if (response.data.status === 'success') {
          setNews(response.data.news);
        } else {
          setError('Failed to fetch news.');
        }
      } catch (err) {
        setError('An error occurred while fetching data.');
      } finally {
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  if (loading) {
    return <div className="text-center py-10">Loading news...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500 py-10">Error: {error}</div>;
  }

  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
      <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-8 border-b-4 border-green-500 inline-block">
        Latest News
      </h1>
      <div className="grid grid-cols-1 gap-6">
        {news.map((item) => (
          <div
            key={item.nid}
            className="bg-white shadow-md rounded-lg overflow-hidden border flex flex-col sm:flex-row relative transform transition-all duration-300 hover:shadow-lg hover:scale-105" // Hover effect added
            style={{ minHeight: '200px' }}
          >
            {/* Image Section */}
            <div className="w-full sm:w-1/3 h-48 sm:h-auto relative bg-gray-200 flex-shrink-0">
              <img
                src={item.new_img}
                alt={item.ntitle}
                className="absolute inset-0 w-full h-full object-cover"
              />
            </div>

            {/* Content Section */}
            <div className="w-full sm:w-2/3 p-4 flex flex-col">
              <h2 className="text-xl font-bold text-gray-800 mb-2">{item.ntitle}</h2>
              <div
                className="text-gray-600 text-sm leading-relaxed overflow-hidden"
                style={{
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: '5',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.ndesc) }}
              ></div>
            </div>

            {/* Vertical Line */}
            <div className="absolute top-0 right-0 h-full w-1 bg-blue-900 hidden sm:block"></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default News;
