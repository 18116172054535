import React from 'react';
import Weather from '../components/Weather';
import priceData from '../data/prices.json'; 

const priceList = Object.keys(priceData).map((category) => ({
  category,
  items: Object.keys(priceData[category]).map((item) => ({
    item,
    price: priceData[category][item],
  })),
}));

const WeatherAndPrice = () => {
  return (
    <div className="flex flex-col md:flex-row w-full max-w-6xl mx-auto mt-16 bg-white shadow-md rounded-lg p-4 gap-4">
      
      {/* Weather Section */}
      <div className="flex-1 flex flex-col space-y-4 p-4 bg-white pb-16 min-h-[200px]">
        <div className="mb-4">
          <h2 className="relative text-3xl font-extrabold text-gray-800 inline-block">
            <span className="absolute inset-x-0 -bottom-1 h-1 bg-blue-500 rounded-full"></span>
            Updates:
          </h2>
        </div>
        {/* Weather Component */}
        <Weather />
      </div>

      {/* Price List Section */}
      <div className="flex-1 flex flex-col space-y-4 p-4 bg-gray-50 rounded-md shadow">
        <h4 className="font-extrabold text-gray-400 inline-block">Price List:</h4>

        {/* Price Table */}
        <table className="w-full table-auto text-left">
          <thead>
            <tr className="border-b">
              <th className="py-2 px-4 font-bold text-lg text-gray-700">Item</th>
              <th className="py-2 px-4 text-right text-lg font-bold text-gray-700">Price</th>
            </tr>
          </thead>
          <tbody>
            {priceList.map((category, categoryIndex) => (
              <React.Fragment key={categoryIndex}>
                {/* Item Rows */}
                {category.items.map((item, itemIndex) => (
                  <tr 
                    key={itemIndex} 
                    className={`border-b ${itemIndex % 2 === 0 ? 'bg-gray-100' : ''} hover:bg-gray-200`}>
                    <td className="py-2 px-4 font-semibold text-gray-500">{item.item}</td>
                    <td className="py-2 px-4 font-bold text-right text-red-900">₹{item.price}</td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default WeatherAndPrice;
