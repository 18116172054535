import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import servicesData from "../data/hlServices.json";
import DOMPurify from 'dompurify';

const TabSection = () => {
    const [activeTab, setActiveTab] = useState(1);
    const [tabData, setTabData] = useState([]);
    const navigate = useNavigate();

    // Fetch data based on active tab
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (activeTab === 2) {
                    // Fetch News
                    const response = await fetch("https://hellolocal.in/api.php?action=get_news");
                    const data = await response.json();
                    if (data.status === "success" && Array.isArray(data.news)) {
                        setTabData(data.news.slice(0, 5)); // Extract top 5 news
                    } else {
                        throw new Error("Invalid news data format");
                    }
                } else if (activeTab === 3) {
                    // Fetch Events
                    const response = await fetch("https://hellolocal.in/api.php?action=get_events");
                    const data = await response.json();
                    if (data.status === "success" && Array.isArray(data.events)) {
                        setTabData(data.events.slice(0, 5)); // Extract top 5 events
                    } else {
                        throw new Error("Invalid events data format");
                    }
                } 
                else if (activeTab === 4) {
                    // Fetch jobs
                    const response = await fetch("https://hellolocal.in/api.php?action=get_jobs");
                    const data = await response.json();
                    if (data.status === "success" && Array.isArray(data.services)) {
                        setTabData(data.services.slice(0, 5)); // Extract top 5 jobs
                    } else {
                        throw new Error("Invalid jobs data format");
                    }
                }else if (activeTab === 5) {
                    // Use services from JSON file
                    setTabData(servicesData.slice(0, 5));
                } else {
                    setTabData([]);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                setTabData([
                    {
                        title: "Error Loading Data",
                        imgUrl: "https://via.placeholder.com/150",
                        description: "Please try again later."
                    }
                ]);
            }
        };

        fetchData();
    }, [activeTab]);



    // Navigate to full list
    const handleShowMore = () => {
        if (activeTab === 2) navigate("/news");
        else if (activeTab === 3) navigate("/events");
        else if (activeTab === 4) navigate("/jobs");
        else if (activeTab === 5) navigate("/services");
    };

    return (
        <section id="features" className="w-full max-w-4xl mx-auto px-4 mt-4 mb-4">
            <div className="section-title text-center mb-12">
                <h2 className="relative text-3xl font-extrabold text-gray-800 dark:text-white inline-block">
                    Explore Rajahmundry
                    <span className="absolute inset-x-0 -bottom-1 h-1 bg-blue-500 rounded-full"></span>
                </h2>
                <p className="text-gray-600 dark:text-white mt-4 text-md">
                    Discover the best of Rajahmundry through our curated highlights.
                </p>
            </div>

            {/* Tabs */}
            <div className="flex justify-center gap-4 p-2 mb-6 overflow-x-auto dark:text-black no-scrollbar">
                {["Home", "News", "Events", "Jobs", "Services"].map((tab, index) => (
                    <button
                        key={index}
                        onClick={() => setActiveTab(index + 1)}
                        className={`px-2 sm:px-3 py-2 text-xs sm:text-sm font-bold rounded ${activeTab === index + 1 ? "bg-purple-900 text-white" : "bg-gray-200 "
                            } whitespace-nowrap`}
                    >
                    <i
                        className={`ri-${tab.toLowerCase() === 'home' ? 'home' :
                        tab.toLowerCase() === 'news' ? 'newspaper' :
                        tab.toLowerCase() === 'events' ? 'calendar' :
                        tab.toLowerCase() === 'jobs' ? 'briefcase' :
                        tab.toLowerCase() === 'services' ? 'service' : ''}-line`}
                    ></i> {tab}
                    </button>
                ))}
            </div>

            {/* Tab Content */}
            <div className="flex flex-col items-center space-y-6 mb-6">
                {activeTab === 1 && (
                    <div className="flex justify-center items-center">
                        {/* YouTube Video */}
                        <div className="flex-shrink-0">
                            <iframe
                                width="100%"
                                height="200"
                                src="https://www.youtube.com/embed/sqSjm10aN7M"
                                title="Hello Local Video"
                                className="rounded-md"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                )}

                {activeTab > 1 && (
                <div>
                    <h3 className="text-xl font-bold mb-4">
                    {activeTab === 2 ? "Top 5 News" : activeTab === 3 ? "Top 5 Events" : activeTab === 4 ? "Top 5 Jobs" : "Top 5 Services"}
                    </h3>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6 max-w-3xl mx-auto">
                    {tabData.map((item, index) => (
                        <div
                        key={index}
                        className="flex items-center p-4 bg-gray-100 rounded-lg shadow-md w-full h-36 relative overflow-hidden transform transition duration-300 hover:scale-105 hover:shadow-lg"
                        >
                        <img
                            src={activeTab === 2 ? item.new_img : activeTab === 3 ? item.event_img : activeTab === 4 ? item.service_img : item.imgUrl}
                            alt={item.ntitle || item.etitle || item.service_name || item.title}
                            className="w-20 h-20 object-cover rounded-lg mr-4"
                        />
                        <div className="flex-1 flex flex-col justify-between overflow-hidden">
                            <h4 className="font-bold text-lg">{item.ntitle || item.etitle || item.service_name || item.title}</h4>
                            <p className="text-sm text-gray-600 truncate" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.ndesc || item.edescription || item.loc || item.description) }}></p>
                        </div>
                        <div className="w-2 bg-blue-900 h-full rounded-r-md absolute right-0 top-0"></div>
                        </div>
                    ))}
                    </div>
                    <div className="text-center mt-4">
                    <button
                        onClick={handleShowMore}
                        className="px-6 py-2 font-bold text-white bg-blue-900 rounded hover:bg-blue-700"
                    >
                        Show More
                    </button>
                    </div>
                </div>
                )}

            </div>
        </section>
    );
};

export default TabSection;
