import React, { useState, useEffect } from "react";
import styled from "styled-components";
import shopData from "../data/shop.json"; // Assuming the JSON is in `../data/shop.json`
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const App = () => {
    const [categories, setCategories] = useState([]); // State for categories
    const [cart, setCart] = useState([]); // State for cart
    const [phoneNumber, setPhoneNumber] = useState(""); // State for phone number

    useEffect(() => {
        // Load categories from JSON
        setCategories(shopData);
    }, []);
    const removeFromCart = (product) => {
        setCart(cart.filter((item) => item.name !== product.name));
    };
    
    const addToCart = (product) => {
        const existingProduct = cart.find((item) => item.name === product.name);
        if (existingProduct) {
            setCart(
                cart.map((item) =>
                    item.name === product.name
                        ? { ...item, count: item.count + 1 }
                        : item
                )
            );
        } else {
            setCart([...cart, { ...product, count: 1 }]);
        }
    };

    const handleSubmit = async () => {
        if (!phoneNumber) {
            alert("Please enter your phone number.");
            return;
        }

        try {
            const cartData = cart.map((item) => ({
                name: item.name,
                count: item.count,
                price: item.price,
            }));

            const response = await fetch("/sendmail.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ cart: cartData, phone: phoneNumber }), // Include phone number
            });

            if (response.ok) {
                const result = await response.json(); // Expecting a JSON response
                if (result.status === "success") {
                    alert("Cart email sent successfully!");
                    setCart([]); // Clear the cart after successful submission
                    setPhoneNumber(""); // Clear the phone number field
                } else {
                    alert("Error sending cart email: " + (result.error || "Unknown error."));
                }
            } else {
                alert("Failed to send cart email. Please try again.");
            }
        } catch (error) {
            console.error("Error:", error);
            alert("An unexpected error occurred. Please try again later.");
        }
    };

    return (
        <StyledWrapper>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
                <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-10 border-b-4 border-green-500 inline-block">
                    Categories
                </h1>
                <div className="container">
                    <div className="products">
                        {categories.map((category) => (
                            <div key={category.name} className="category">
                                <h2 className="category-heading">{category.name}</h2>
                                <div className="category-products">
                                    {category.products.map((product) => (
                                        <Card
                                            key={product.name}
                                            title={product.name}
                                            description={product.description}
                                            price={product.price}
                                            image={product.image}
                                            onAddToCart={() => addToCart(product)}
                                        />
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="cart">
    <h2 className="margin-top:2px">Cart</h2>
    <ul>
        {cart.map((item, index) => (
            <li key={index} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <span>
                    {item.name} x {item.count}
                </span>
                <FontAwesomeIcon
                    icon={faTrash}
                    onClick={() => removeFromCart(item)}
                    style={{
                        color: "red",
                        cursor: "pointer",
                        fontSize: "16px",
                        marginLeft: "10px",
                    }}
                    title="Remove Item"
                />
            </li>
        ))}
    </ul>
    {cart.length > 0 && (
        <>
            <div className="phone-input">
                <label htmlFor="phoneNumber">Phone Number:</label>
                <input
                    type="tel"
                    id="phoneNumber"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder="Enter your phone number"
                />
            </div>
            <button className="submit-btn" onClick={handleSubmit}>
                Submit Cart
            </button>
        </>
    )}
</div>

                </div>
            </div>
        </StyledWrapper>
    );
};

const Card = ({ title, description, price, image, onAddToCart }) => {
    return (
        <div className="card">
            <img src={image} alt={title} className="card-image" />
            <div className="card-title">{title}</div>
            <div className="card-subtitle">{description}</div>
            <div className="card-footer">
                <div className="card-price">₹{price.toFixed(2)}</div>
                <button className="card-btn" onClick={onAddToCart}>
                    Add to Cart
                </button>
            </div>
        </div>
    );
};


const StyledWrapper = styled.div`
 .container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  display: grid;
  grid-template-columns: 3fr 1fr; /* Products take 3 parts, cart takes 1 part */
  gap: 20px;

  /* Stack layout for smaller screens */
  @media (max-width: 768px) {
    display: block; /* Stack products and cart vertically */
  }
}

.cart {
   position: absolute; /* Keep the cart positioned absolutely within the container */
  top: 60px; /* Adjust this value based on your navbar height */
  right: 0; /* Push the cart to the right border */
  background: ${(props) =>
        props.isCartHighlighted ? "rgba(255, 215, 0, 0.1)" : "#f9f9f9"};
  padding: 20px;
  border: 2px solid ${(props) => (props.isCartHighlighted ? "gold" : "#323232")};
  border-radius: 5px;
  box-shadow: 4px 4px #323232;
  z-index: 10;
  transition: all 0.3s;
  max-height: fit-content; /* Ensure height adjusts to content */

  /* Adjustments for mobile view */
  @media (max-width: 768px) {
    position: relative; /* Reset positioning for smaller screens */
    width: 100%; /* Full width for mobile */
    margin-top: 20px;
  }
}

  .products {
    flex: 1;
  }
  .categories {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* 3 cards per row */
    gap: 20px;
  }
  .category {
    margin-bottom: 40px;
  }

  .category-heading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
  }

 .category-products {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  gap: 5px;
}
@media (max-width: 768px) {
  .category-products {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr)); /* Smaller cards for narrow screens */
    gap: 8px;
  }
}

  .phone-input label {
    font-weight: bold;
  }

  .cart h2 {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
    color: #323232;
  }

  .cart ul {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
  }

  .cart ul li {
   margin-bottom: 8px;
    font-size: 16px;
    color: #666;
  }

  .submit-btn {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    margin-top:15px;
    font-weight: bold;
    color: #fff;
    background-color: #2d8cf0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
 .phone-input input {
    width: 100%;
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 5px;
    margin-top: 5px;
  }
  .submit-btn:hover {
    background-color: #1a73e8;
  }

  .card {
     width: 100%; /* Automatically fits into the grid column */
  max-width: 230px; /* Optional: Limit the card width */
    background: #fff;
    border: 2px solid #323232;
    box-shadow: 4px 4px #323232;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    gap: 10px;
  }

  .card-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 5px;
  }

  .card-title {
    font-size: 20px;
    font-weight: bold;
    color: #323232;
  }

  .card-subtitle {
    font-size: 14px;
    color: #666;
  }

  .card-footer {
   margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .card-price {
    font-size: 18px;
    font-weight: bold;
    color: #323232;
  }

  .card-btn {
    padding: 4px 8px;
    margin-left:8px;
    background-color: #2d8cf0;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
  }

  .card-btn:hover {
    background-color: #1a73e8;
  }
`;

export default App;
