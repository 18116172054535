import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Card = () => {
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [services, setServices] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          "https://hellolocal.in/api.php?action=get_categories"
        );
        const data = await response.json();
        if (data.status === "success") {
          setCategories(data.categories);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const fetchSubcategories = async (cat_id) => {
    try {
      const response = await fetch(
        `https://hellolocal.in/api.php?action=get_subcategories&cat_id=${cat_id}`
      );
      const data = await response.json();
      if (data.status === "success") {
        setSubcategories(data.subcategories);
        setSelectedCategory(categories.find((c) => c.cid === cat_id));
        setServices([]); // Reset services when navigating to subcategories
      } else {
        setSubcategories([]);
      }
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

  const fetchServices = async (sub_cat_id) => {
    try {
      const response = await fetch(
        `https://hellolocal.in/api.php?action=get_services&sub_cat_id=${sub_cat_id}`
      );
      const data = await response.json();
      if (data.status === "success") {
        setServices(data.services);
        setSelectedSubcategory(subcategories.find((s) => s.sub_cat_id === sub_cat_id));
      } else {
        setServices([]);
      }
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const handleBackToCategories = () => {
    setSelectedCategory(null);
    setSubcategories([]);
    setServices([]);
  };

  // const handleBackToSubcategories = () => {
  //   setSelectedSubcategory(null);
  //   setServices([]);
  // };

  return (
    <StyledWrapper>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-10 border-b-4 border-green-500 inline-block">
          Categories
        </h1>

        {/* Breadcrumb Navigation */}
      
          <nav className="breadcrumb">
            <span onClick={handleBackToCategories} className="breadcrumb-link">
              Info
            </span>
            {selectedCategory && (
              <>
                <span> &gt; </span>
                <span onClick={() => setSelectedSubcategory(null)} className="breadcrumb-link">
                  {selectedCategory.ctitle}
                </span>
              </>
            )}
            {selectedSubcategory && (
              <>
                <span> &gt; </span>
                <span className="breadcrumb-link">{selectedSubcategory.sub_cat_name}</span>
              </>
            )}
          </nav>


        {/* Services View */}
        {selectedSubcategory ? (
          <div>
           
            {services.length > 0 ? (
              <div className="service-container">
                {services.map((service) => (
                  <div className="service-card" key={service.id}>
                    <img
                      src={service.service_img}
                      alt={service.service_name}
                      className="service-img"
                    />
                    <div><h4><strong>{service.service_name}</strong></h4></div>
                    <div
                      className="description"
                      dangerouslySetInnerHTML={{ __html: service.service_desc }}
                    />
                    <div className="contact-details">
                      <div className="contact-item">
                        <span className="icon">🎯</span>
                        <span>{service.service_address}</span>
                      </div>
                      <div className="contact-item">
                        <span className="icon">📞</span>
                        <span>{service.contact}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p>No services found for this subcategory.</p>
            )}
          </div>
        ) : selectedCategory ? (
          <div>
          
            {subcategories.length > 0 ? (
              <div className="card-container">
                {subcategories.map((subcategory) => (
                  <div
                    className="card"
                    key={subcategory.sub_cat_id}
                    onClick={() => fetchServices(subcategory.sub_cat_id)}
                  >
                    <img
                      src={subcategory.sub_cat_img}
                      alt={subcategory.sub_cat_name}
                      className="card__image"
                    />
                    <h4 className="card__title">{subcategory.sub_cat_name}</h4>
                  </div>
                ))}
              </div>
            ) : (
              <p>No subcategories found for this category.</p>
            )}
          </div>
        ) : (
          <div className="card-container">
            {categories.map((category) => (
              <div
                className="card"
                key={category.cid}
                onClick={() => fetchSubcategories(category.cid)}
              >
                <img
                  src={category.cimage}
                  alt={category.ctitle}
                  className="card__image"
                />
                <h3 className="card__title">{category.ctitle}</h3>
              </div>
            ))}
          </div>
        )}
      </div>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  
  .card-container,
  .service-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
    gap: 1rem;
    padding: 1rem;
  }

  .card {
    --border-radius: 0.75rem;
    --primary-color: #7257fa;
    --secondary-color: #3c3852;
    font-family: "Arial";
    padding: 1rem;
    cursor: pointer;
    border-radius: var(--border-radius);
    background: #f1f1f3;
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 3%);
    position: relative;
    overflow: hidden;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .card__image {
    width: 80%;
    height: 120px;
    border-radius: var(--border-radius);
    object-fit: contain;
  }

  .card__title {
    min-height: 40px; /* Adjust as per your content */
    font-size: 1.1rem;
    font-weight: bold;
    color: var(--secondary-color);
    margin-top: 0.8rem;
  }

  .card__arrow {
    position: absolute;
    background: var(--primary-color);
    padding: 0.4rem;
    border-top-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    bottom: 0;
    right: 0;
    transition: 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card:hover .card__title {
    color: var(--primary-color);
    text-decoration: underline;
  }

  .card:hover .card__arrow {
    background: #111;
  }

   .service-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
  }

  .service-card {
    width: 300px; /* Fixed width */
    height: 500px; /* Fixed height */
    background: #f4f4f4;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    overflow: hidden;
    transition: transform 0.2s ease-in-out;
  }

  .service-card:hover {
    transform: scale(1.05);
  }

  .service-img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
  }

  .service-card h4 {
    margin-top: 15px;
    font-size: 1.2rem;
    color: #333;
    text-align: center;
  }

  .service-card p {
    font-size: 1rem;
    color: #555;
    margin-top: 10px;
    height: 80px; /* Fixed height for description */
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .contact-details {
    font-size: 1rem;
    color: #333;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 50px;
  }

  .contact-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }


  .icon {
    margin-right: 0.5rem;
    font-size: 1.2rem;
  }

 .breadcrumb {
    font-size: 1rem;
    margin-bottom: 1rem;
    color: #4a5568;
  }
  .breadcrumb-link {
    cursor: pointer;
    color: #2b6cb0;
    font-weight: bold;
  }
  .breadcrumb-link:hover {
    text-decoration: underline;
  }
  .back-button {
    background: #4a5568;
    color: white;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
  }

  .back-button:hover {
    background: #3c3852;
  }
     @media (max-width: 768px) {
    .card-container,
    .service-container {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
  }
`;

export default Card;
