import React from 'react';
import Content from './Content';
import TabSection from '../components/Tabs';
import Top10List from '../components/Top10List';
import Contact from '../components/Contact';
import Updates from './Updates';
import Footer from '../components/Footer';
import ScrollToTopButton from '../components/scrollTop';
import Corousel from './Corousel';
const Home = () => {
  return (
    <div className="flex flex-col items-center">
      <Corousel />
      <Content />
      <TabSection />
      <Updates />   
      <Top10List />
      <Contact />
      <Footer />
      <ScrollToTopButton />
    </div>
  );
};

export default Home;
