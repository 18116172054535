import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-cream-50 p-4 text-black flex flex-col items-center">
      <div className="flex space-x-4 mb-2">
        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebook} className="text-blue-900 dark:text-white" />
        </a>
        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faTwitter} className="text-blue-900 dark:text-white" />
        </a>
        <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} className="text-blue-900 dark:text-white" />
        </a>
      </div>
      <div className="text-center">
        <p className='font-semibold text-gray-900 dark:text-white'>
        Designed by <strong><a href="www.hellolocal.in">Hello Local</a></strong> . &copy; {currentYear}  All rights reserved. Made with ❤️.
        </p>
      </div>
    </footer>
  );
};

export default Footer;

