import React from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import data from '../data/topList.json';

const Top10List = () => {
  

  const renderCard = ({ title, image, items }) => (
    <div className="card bg-white rounded-md shadow-md border flex flex-col" style={{ width: '280px', height: '500px' }}>
      {/* Header Image */}
      <div>
        <img
          src={image}
          alt={title}
          className="w-full h-32 object-cover rounded-t-md"
          loading="lazy"
        />
      </div>
  
      {/* Header Title */}
      <h2 className="text-base font-semibold text-white bg-blue-900 py-1 px-3 text-center">
        {title}
      </h2>
  
      {/* List */}
      <ul className="text-gray-700 mt-2 flex-grow overflow-auto px-3">
        {items.map((item, index) => (
          <li
            key={index}
            className="flex items-center gap-2 py-2 transition duration-300 ease-in-out hover:bg-gray-100 hover:shadow-md rounded-md"
          >
            {/* Circular Image */}
            <img
              src={item.image}
              alt={item.name}
              className="w-12 h-12 rounded-full object-cover"
              loading="lazy"
            />
  
            {/* Content */}
            <div>
              <h3 className="font-medium text-sm mt-2">{item.name}</h3>
              <p className="text-xs text-gray-500 mb-2">{item.description}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
  


  return (
    <div className="container mx-auto">
      {/* Section Heading */}


      <div className="section-title text-center mb-12 mt-12">
        <h2 className="relative text-4xl font-extrabold text-gray-800 dark:text-white inline-block">
          {/* Decorative underline */}
          <span className="absolute inset-x-0 -bottom-1 h-1 bg-blue-500 rounded-full"></span>
          Top List
        </h2>
        <p className="text-gray-600 dark:text-white mt-4 text-lg">
          Explore the highlights of our offerings with style!
        </p>
      </div>
      {/* Cards Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 justify-center">
        {data.map((category, index) => (
          <div key={index} className="flex justify-center">
            {renderCard(category)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Top10List