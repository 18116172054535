import React, { useState } from 'react';
import { faMapMarkerAlt, faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });
  const [statusMessage, setStatusMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(phone);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatusMessage('Sending...');

    // Phone validation
    if (!validatePhone(formData.phone)) {
      setStatusMessage('Please enter a valid phone number.');
      return;
    }

    try {
      const response = await fetch('/sendEmail.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const result = await response.json();
        if (result.status === 'success') {
          setStatusMessage('Email sent successfully!');
          setFormData({ name: '', email: '', phone: '', message: '' });
          setTimeout(() => setStatusMessage(''), 5000);
        } else {
          setStatusMessage('Error sending email.');
        }
      } else {
        setStatusMessage('Failed to send email. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      setStatusMessage('An unexpected error occurred. Please try again later.');
    }
  };

  return (
    <div id="contact" className="flex flex-col md:flex-row w-full max-w-6xl mx-auto mt-16 bg-white shadow-md rounded-lg p-4 gap-4">
      {/* Address Section */}
      <div className="flex-1 flex flex-col space-y-4 p-4 bg-white pb-16 min-h-[200px]">
        <div className="mb-4">
          <h2 className="relative text-3xl font-extrabold text-gray-800 inline-block">
            <span className="absolute inset-x-0 -bottom-1 h-1 bg-blue-500 rounded-full"></span>
            Contact Us
          </h2>
        </div>
        <div className="flex items-center mb-8">
          <div className="flex items-center justify-center bg-indigo-400 text-white rounded-full p-2 mr-2">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="h-5 w-5" aria-label="Location" />
          </div>
          <span className="font-semibold text-gray-700">
            #26-2-4, Kambalacheruvu, Rajahmundry
          </span>
        </div>
        <div className="flex items-center mb-8">
        <div className="flex items-center justify-center bg-indigo-400 text-white rounded-full p-2 mr-2">
          <FontAwesomeIcon icon={faPhoneAlt} className="h-5 w-5" aria-label="Phone" />
          </div>
          <span className="font-semibold text-gray-700">9949100200</span>
        </div>
        <div className="flex items-center mb-8">
        <div className="flex items-center justify-center bg-indigo-400 text-white rounded-full p-2 mr-2">
          <FontAwesomeIcon icon={faEnvelope} className="h-5 w-5" aria-label="Email" />
        </div>

          <span className="font-semibold text-gray-700">info@hellolocal.in</span>
        </div>
      </div>

      {/* Form Section */}
      <div className="flex-1 flex flex-col space-y-4 p-4 bg-gray-50 rounded-md shadow">
        <h4 className="font-extrabold text-gray-400 inline-block">For Events Promotions:</h4>
        <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
          {/* Name and Mobile Fields */}
          <div className="flex flex-col md:flex-row md:space-x-2 gap-y-4">
            <input
              type="text"
              name="name"
              placeholder="Name"
              className="flex-1 p-2 border border-gray-300 rounded focus:border-blue-500 focus:outline-none"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              type="tel"
              name="phone"
              placeholder="Mobile"
              className="flex-1 p-2 border border-gray-300 rounded focus:border-blue-500 focus:outline-none"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>

          {/* Email Field */}
          <input
            type="email"
            name="email"
            placeholder="Email"
            className="w-full p-2 border border-gray-300 rounded focus:border-blue-500 focus:outline-none"
            value={formData.email}
            onChange={handleChange}
            required
          />

          {/* Message Field */}
          <textarea
            name="message"
            placeholder="Message"
            className="w-full p-2 border border-gray-300 rounded focus:border-blue-500 focus:outline-none"
            rows="4"
            value={formData.message}
            onChange={handleChange}
            required
          />

          {/* Submit Button */}
          <button
            type="submit"
            className="px-6 py-2 text-center bg-blue-900 text-white rounded mx-auto hover:bg-emerald-700 transition-colors text-sm"
          >
            Submit
          </button>
        </form>

        {/* Status Message */}
        {statusMessage && (
          <div
            className={`w-full mt-4 p-2 text-center font-semibold rounded ${
              statusMessage.includes('Error') ? 'bg-red-200 text-red-700' : 'bg-green-200 text-green-700'
            }`}
          >
            {statusMessage}
          </div>
        )}
      </div>
    </div>
  );
};

export default Contact;
