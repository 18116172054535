import { useState } from 'react';
import { Link } from 'react-router-dom';

const Navbar = ({ toggleTheme, currentTheme }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="fixed w-full top-0 left-0 z-50 shadow-lg bg-gradient-to-r from-blue-500 to-purple-600 dark:from-gray-800 dark:to-gray-900">
      <div className="flex items-center justify-between px-4 py-2">
        <Link to="/" className="flex items-center space-x-2 no-underline">
          <img
            src={'https://hellolocal.in/react/favicon.png'}
            alt="HL Logo"
            className="h-10 w-10 object-contain"
          />
          <h1 className="text-xl font-bold text-white tracking-wide dark:text-gray-200">
            Hello Local
          </h1>
        </Link>
        {/* Dark Mode Toggle */}
        {/* <button
          onClick={toggleTheme}
          className="ml-4 p-2 rounded-full text-gray-800 dark:text-gray-200 hover:bg-gray-300 "
        >
          {currentTheme === 'light' ? '🌙' : '☀️'}
        </button> */}

        {/* Hamburger Button (Mobile Menu Toggle) */}
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="inline-flex items-center justify-center p-2 rounded-md text-white md:hidden hover:bg-purple-700 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
        >
          <span className="sr-only">Open main menu</span>
          {isMenuOpen ? (
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          )}
        </button>


        {/* Desktop Menu */}
        <nav className="hidden md:flex space-x-6">
      
        <Link
            to="/info"
            className="text-white font-medium no-underline hover:text-gray-300 dark:hover:text-gray-400 transition duration-200"
          >
            Info
          </Link>
          <Link
            to="/news"
            className="text-white font-medium no-underline hover:text-gray-300 dark:hover:text-gray-400 transition duration-200"
          >
            News
          </Link>
          <Link
            to="/events"
            className="text-white font-medium no-underline hover:text-gray-300 dark:hover:text-gray-400 transition duration-200"
          >
            Events
          </Link>
          <Link
            to="/services"
            className="text-white font-medium no-underline hover:text-gray-300 dark:hover:text-gray-400 transition duration-200"
          >
            Services
          </Link>
          <Link
            to="/jobs"
            className="text-white font-medium no-underline hover:text-gray-300 dark:hover:text-gray-400 transition duration-200"
          >
            Jobs
          </Link>          
          <Link
            to="/blog"
            className="text-white font-medium no-underline hover:text-gray-300 dark:hover:text-gray-400 transition duration-200"
          >
            Blog
          </Link>
          <Link
            to="/shop"
            className="text-white font-medium no-underline hover:text-gray-300 dark:hover:text-gray-400 transition duration-200"
          >
            Shop
          </Link>
          <Link
            to="/contact"
            className="text-white font-medium no-underline hover:text-gray-300 dark:hover:text-gray-400 transition duration-200"
          >
            Contact
          </Link>
        </nav>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="md:hidden bg-purple-700 dark:bg-gray-800">
          <div className="px-4 py-2 space-y-1">
           <Link
              to="/info"
              onClick={() => setIsMenuOpen(false)}
              className="block px-3 py-2 rounded-md text-base font-medium text-white no-underline hover:bg-purple-600 dark:hover:bg-gray-700"
            >
              Info
            </Link>
            <Link
              to="/news"
              onClick={() => setIsMenuOpen(false)}
              className="block px-3 py-2 rounded-md text-base font-medium text-white no-underline hover:bg-purple-600 dark:hover:bg-gray-700"
            >
              News
            </Link>
            <Link
              to="/events"
              onClick={() => setIsMenuOpen(false)}
              className="block px-3 py-2 rounded-md text-base font-medium text-white no-underline hover:bg-purple-600 dark:hover:bg-gray-700"
            >
              Events
            </Link>
            <Link
              to="/services"
              onClick={() => setIsMenuOpen(false)}
              className="block px-3 py-2 rounded-md text-base font-medium text-white no-underline hover:bg-purple-600 dark:hover:bg-gray-700"
            >
              Services
            </Link>
            <Link
              to="/jobs"
              onClick={() => setIsMenuOpen(false)}
              className="block px-3 py-2 rounded-md text-base font-medium text-white no-underline hover:bg-purple-600 dark:hover:bg-gray-700"
            >
              Jobs
            </Link>
          
           
            <Link
              to="/blog"
              onClick={() => setIsMenuOpen(false)}
              className="block px-3 py-2 rounded-md text-base font-medium text-white no-underline hover:bg-purple-600 dark:hover:bg-gray-700"
            >
              Blog
            </Link>
            <Link
              to="/shop"
              onClick={() => setIsMenuOpen(false)}
              className="block px-3 py-2 rounded-md text-base font-medium text-white no-underline hover:bg-purple-600 dark:hover:bg-gray-700"
            >
              Shop
            </Link>
            <Link
              to="/contact"
              onClick={() => setIsMenuOpen(false)}
              className="block px-3 py-2 rounded-md text-base font-medium text-white no-underline hover:bg-purple-600 dark:hover:bg-gray-700"
            >
              Contact
            </Link>
          </div>
        </div>
      )}
    </header>
  );
};

export default Navbar;
