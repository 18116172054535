import React, { useState, useEffect, useRef } from "react";
import Button from "../components/Button"; // Import Button component

const Carousel = () => {
  const [events, setEvents] = useState([]);
  const [banners, setBanners] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const modalRef = useRef();
  const carouselRef = useRef(null);

  useEffect(() => {
    // Fetch events from the API
    const fetchEvents = async () => {
      try {
        const response = await fetch(
          "https://hellolocal.in/api.php?action=get_events"
        );
        const data = await response.json();
        if (data.status === "success") {
          setEvents(data.events); // Set the fetched events
        } else {
          console.error("Failed to fetch events");
        }
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    // Fetch banners from the API
    const fetchBanners = async () => {
      try {
        const response = await fetch(
          "https://hellolocal.in/api.php?action=get_banners"
        );
        const data = await response.json();
        if (data.status === "success") {
          setBanners(data.banners); // Set the fetched banners
        } else {
          console.error("Failed to fetch banners");
        }
      } catch (error) {
        console.error("Error fetching banners:", error);
      }
    };

    fetchEvents();
    fetchBanners();
  }, []);

  useEffect(() => {
    const totalItems = events.length + banners.length;
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % totalItems);
    }, 3000); // Auto-scroll every 3 seconds
    return () => clearInterval(interval);
  }, [events, banners]);

  // Handle swipe gestures (touch/mouse drag)
  let touchStartX = 0;
  let touchEndX = 0;

  const handleTouchStart = (e) => {
    touchStartX = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    touchEndX = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    if (touchStartX - touchEndX > 50) {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % (events.length + banners.length)); // Swipe left
    } else if (touchStartX - touchEndX < -50) {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? events.length + banners.length - 1 : prevIndex - 1
      ); // Swipe right
    }
  };

  const handleImageClick = (index, isEvent) => {
    if (isEvent) {
      setSelectedEvent(events[index]);
      setShowModal(true);
    }
  };

  const closeModal = (e) => {
    if (modalRef.current && modalRef.current.contains(e.target)) return;
    setShowModal(false);
    setSelectedEvent(null);
  };

  const renderCarouselContent = () => {
    const totalItems = [...events, ...banners];
    const currentItem = totalItems[currentIndex];

    if (currentIndex < events.length) {
      return (
        <>
          <img
            src={currentItem.event_img}
            alt={currentItem.etitle}
            className="w-full h-full object-cover cursor-pointer"
            onClick={() => handleImageClick(currentIndex, true)}
          />
          <div className="absolute bottom-0 w-full text-center bg-gradient-to-t from-black to-transparent text-white p-4">
            <h3 className="text-sm sm:text-lg lg:text-xl font-bold">
              {currentItem.etitle}
            </h3>
          </div>
        </>
      );
    } else {
      return (
        <>
          <img
            src={currentItem.banner_img}
            alt={currentItem.btitle}
            className="w-full h-full object-cover cursor-pointer"
          />
          <div className="absolute bottom-0 w-full text-center bg-gradient-to-t from-black to-transparent text-white p-4">
            <h3 className="text-sm sm:text-lg lg:text-xl font-bold">
              {currentItem.btitle}
            </h3>
          </div>
        </>
      );
    }
  };

  return (
    <div className="w-full max-w-6xl mx-auto mt-20">
      {/* Carousel */}
      <div
        ref={carouselRef}
        className="relative w-[95%] sm:w-full h-[300px] sm:h-[350px] md:h-[500px] lg:h-[600px] overflow-hidden rounded-lg mx-auto"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {events.length + banners.length > 0 && renderCarouselContent()}
      </div>

      {/* Navigation Dots */}
      <div className="flex justify-center mt-4 space-x-2">
        {[...events, ...banners].map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentIndex(index)}
            className={`w-3 h-3 rounded-full transition-all duration-300 ${
              index === currentIndex ? "bg-blue-500 scale-125" : "bg-gray-300"
            }`}
          />
        ))}
      </div>

      {/* Modal */}
      {showModal && selectedEvent && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
          onClick={closeModal}
        >
          <div
            ref={modalRef}
            className="bg-white rounded-lg shadow-lg overflow-hidden w-[90%] sm:w-[80%] md:w-[70%] lg:w-[50%] xl:w-[40%]"
          >
            {/* Image */}
            <img
              src={selectedEvent.event_img}
              alt={selectedEvent.etitle}
              className="w-full h-auto max-h-[400px] sm:max-h-[500px] object-cover"
            />
            {/* Details */}
            <div className="p-4">
              <h2 className="text-lg sm:text-xl md:text-2xl font-bold text-gray-900 dark:text-black text-center">
                {selectedEvent.etitle}
              </h2>
              <div
                className="mt-2 text-sm sm:text-base dark:text-black"
                dangerouslySetInnerHTML={{ __html: selectedEvent.edescription }}
              />
              <Button
                onClick={() => setShowModal(false)}
                label="Close"
                className="mt-4 w-full sm:w-auto mx-auto"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Carousel;
