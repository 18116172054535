import React, { useEffect, useState } from "react";
import axios from "axios";

const BlogPage = () => {
  const [stories, setStories] = useState([]);
  const [selectedStory, setSelectedStory] = useState(null);

  useEffect(() => {
    axios
      .get("https://hellolocal.in/api.php?action=get_stories")
      .then((response) => {
        if (response.data.status === "success") {
          setStories(response.data.stories);
        }
      })
      .catch((error) => console.error("Error fetching stories:", error));
  }, []);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      {/* Blog Title */}
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-4xl font-bold text-gray-900 dark:text-white mt-3 border-b-4 border-green-500 pb-1">
          Blog
        </h1>

        {/* Back Button Only When Story is Selected */}
        {selectedStory && (
          <button
            className="bg-gray-200 text-gray-700 px-4 py-2 rounded-md shadow-md transition hover:bg-gray-300 hover:shadow-lg flex items-center"
            onClick={() => setSelectedStory(null)}
          >
            <span className="mr-2">←</span> Back to Blog
          </button>
        )}
      </div>

      {/* Full Blog Post View */}
      {selectedStory ? (
        <div className="max-w-3xl mx-auto p-6 bg-white shadow-lg rounded-lg">
          <h1 className="text-3xl font-bold text-gray-900 mb-4">{selectedStory.stitle}</h1>

          {/* Full Image */}
          <img className="w-full h-96 object-cover rounded-md shadow-md" src={selectedStory.simage} alt={selectedStory.stitle} />

          {/* Blog Content */}
          <p className="text-gray-700 leading-relaxed mt-6">
            {selectedStory.content.replace(/<[^>]*>?/gm, "")}
          </p>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {stories.map((story) => (
            <div key={story.sid} className="relative bg-white shadow-lg rounded-lg overflow-hidden flex flex-col">
              {/* Image Section */}
              <div className="relative w-full h-52">
                <img className="w-full h-full object-cover" src={story.simage} alt={story.stitle} />

               {/* Date Display (Properly Positioned) */}
               <div className="absolute top-36 left-4 bg-white px-3 py-1.5 rounded-lg text-center shadow-md">
                  <p className="text-xl font-bold text-gray-700 leading-none">
                    {new Date(story.sdate).getDate()}
                  </p>
                  <p className="text-xs uppercase text-gray-500 leading-tight">
                    {new Date(story.sdate).toLocaleString("default", { month: "short" })}
                  </p>
                </div>
              </div>

              {/* Content Section */}
              <div className="p-4 flex flex-col flex-grow">
                {/* Title (Fixed Overflow Issue) */}
                <h2 className="text-lg font-bold leading-snug line-clamp-2 mb-2">
                  {story.stitle}
                </h2>

                {/* Content (Fixed to 3 Lines) */}
                <p className="text-sm text-gray-500 flex-grow overflow-hidden"
                  style={{
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 3, // Restricts to 3 lines
                  }}>
                  {story.content.replace(/<[^>]*>?/gm, "")}
                </p>

                {/* Read More Button Stays at Bottom */}
                <div className="mt-4">
                <button
                     className="bg-blue-600 text-white px-2 py-1 rounded-md shadow-md transition duration-300 hover:bg-blue-700 hover:shadow-lg absolute bottom-4 right-4"
                     onClick={() => setSelectedStory(story)}
                   >
                     Read More →
                   </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default BlogPage;
