import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import servicesData from '../data/hlServices.json';

const Services = () => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    setServices(servicesData);
  }, []);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
      <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-10 border-b-4 border-green-500 inline-block">
        Services
      </h1>
      <div className="flex flex-col gap-12 mt-8">
        {services && services.length > 0 ? (
          services.map((service, index) => (
            <StyledWrapper key={index}>
              <div className="card">
                <div className="image-section">
                  <img src={service.imgUrl} alt={service.title} />
                </div>
                <div className="content-section">
                  <div className="title">
                    <p className="title-text">{service.title}</p>
                  </div>
                  <ul className="service-list">
                    {service.description.map((desc, idx) => (
                      <li key={idx}>{desc}</li>
                    ))}
                  </ul>
                  <p className="contact">{service.contact}</p>
                </div>
              </div>
            </StyledWrapper>
          ))
        ) : (
          <p>No services available at the moment.</p>
        )}
      </div>
    </div>
  );
};

const StyledWrapper = styled.div`
  .card {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 10px 10px #323232;
    border: 3px solid #323232;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s ease;
    width: 100%;
    margin-bottom: 1.5rem; /* Adjust margin as needed */
  }

  .card:hover {
    transform: translateY(-10px);
  }

  .image-section {
    width: 100%;
    height: 200px; /* Set a fixed height for the image section */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .image-section img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (min-width: 768px) {
    .card {
      flex-direction: row;
      width: 95%; /* Adjust width to fit better */
    }

    .image-section {
      width: 45%; /* Slightly increase image width */
      height: auto;
    }

    .content-section {
      width: 55%;
      padding: 2rem;
    }
  }

  .content-section {
    width: 100%;
    padding: 1.5rem;
  }

  .title {
    margin-bottom: 1rem;
  }

  .title-text {
    font-size: 1.75rem;
    font-weight: 700;
  }

  .service-list {
    list-style-type: disc;
    margin-left: 1.25rem;
    margin-bottom: 1.5rem;
    color: #333;
  }

  .contact {
    font-size: 1.1rem;
    color: #555;
    font-weight: 700;
  }
`;

export default Services;