// Button.js
import React from 'react';

const Button = ({ onClick, label, className, type = 'button' }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className="mt-4 px-6 py-2 bg-red-500 text-white rounded hover:bg-red-700 transition mx-auto block"
      >
      {label}
    </button>
  );
};

export default Button;
