import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaShareAlt } from 'react-icons/fa';
import DOMPurify from 'dompurify';

const Services = () => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await fetch('https://hellolocal.in/api.php?action=get_jobs');
        if (!response.ok) {
          throw new Error('Failed to fetch jobs');
        }
        const data = await response.json();
        if (data.status === 'success') {
          const formattedJobs = data.services.map((job) => ({
            id: job.id,
            title: job.service_name,
            imgUrl: job.service_img,
            description: job.service_desc
              .replace(/<ul>/g, '')
              .replace(/<\/ul>/g, '')
              .replace(/<li>/g, '')
              .replace(/<\/li>/g, '')
              .split('\n')
              .filter((item) => item.trim() !== ''),
            contact: job.contact,
            address: job.service_address,
            location: job.loc,
            email: job.email,
            service_area: job.service_area
          }));
          setJobs(formattedJobs);
        } else {
          throw new Error('No jobs found');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchJobs();
  }, []);

  const handleShare = async (job) => {
    const shareData = {
      title: job.title,
      text: `Vacancy: ${job.title} at ${job.location}`,
      url: window.location.href,
    };

    if (navigator.share) {
      try {
        await navigator.share(shareData);
      } catch (err) {
        console.error('Error sharing:', err);
      }
    } else {
      alert('Web Share API not supported. Please use a modern browser.');
    }
  };

  if (loading) return <p>Loading jobs...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
      <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-6 border-b-4 border-green-500 inline-block">
        Available Jobs
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {jobs.length > 0 ? (
          jobs.map((job) => (
            <StyledWrapper key={job.id}>
              <div className="card">
                <div className="image-section">
                  <img src={job.imgUrl} alt={job.title} />
                </div>
                <div className="content-section">
                  <div className="title">
                    <div
                      className="title-text font-bold"
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(job.title) }}
                    />
                  </div>
                  <ul className="service-list">
                    {job.description.map((desc, idx) => (
                      <li key={idx} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(desc) }} />
                    ))}
                  </ul>
                  <p className="contact">
                    <strong className="contacttitle font-bold">Job Location: </strong>
                    {job.service_area} - {job.location}
                  </p>
                  <p className="contact">
                    <strong className="contacttitle font-bold">Phone: </strong>
                    {job.contact}
                  </p>
                  <p className="address">
                    <strong className="contacttitle font-bold">Address: </strong>
                    {job.address}
                  </p>
                </div>
                <div className="share-container">
                  <FaShareAlt className="share-icon" onClick={() => handleShare(job)} />
                </div>
              </div>
            </StyledWrapper>
          ))
        ) : (
          <p>No jobs available at the moment.</p>
        )}
      </div>
    </div>
  );
};

const StyledWrapper = styled.div`
  .card {
    width: 100%;
    max-width: 350px;
    height: auto;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 5px 5px #323232;
    border: 2px solid #323232;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  .card:hover {
    transform: translateY(-5px);
  }
  .image-section {
    width: 100%;
    height: 200px;
    overflow: hidden;
  }
  .image-section img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .content-section {
    flex-grow: 1;
    padding: 10px;
    overflow-wrap: break-word;
    word-break: break-word;
    white-space: normal;
  }
  .title {
    font-size: 1.4rem;
    font-weight: 700;
    margin-bottom: 5px;
    text-align: center;
  }
  .service-list {
    list-style-type: disc;
    margin-left: 15px;
    color: #333;
    max-height: 120px;
    overflow-y: auto;
    white-space: normal;
    padding-left: 10px;
  }
  .contact,
  .address {
    font-size: 1rem;
    color: #555;
    margin: 5px 0;
  }
  .contacttitle {
    font-size: 1rem;
    color: #555;
    margin: 5px 0;
  }
  .share-container {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    background: #f8f8f8;
  }
  .share-icon {
    color: #1da1f2;
    font-size: 1.5rem;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  .share-icon:hover {
    color: #1a91da;
  }
`;

export default Services;
