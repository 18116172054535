import { useState, useEffect } from "react";

const ScrollToTopButton = () => {
  const [showButton, setShowButton] = useState(false);

  // Show button when user scrolls down
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {/* WhatsApp Button (always visible) */}
      <div
      className="tpw_widget_icon"
      style={{
        position: "fixed",
        bottom: "20px",
        left: "20px",
      }}
    >
      <div className="qty_cont">
        <div
          className="tpw_qty"
          style={{
            display: "none",
          }}
        >
          1
        </div>
      </div>
      <a
        href="https://api.whatsapp.com/send?phone=+919700935450"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i
          className="fab fa-whatsapp"
          style={{
            fontSize: "48px",
            color: "green",
          }}
        ></i>
      </a>
    </div>
      {/* Scroll to Top Button (only visible after scrolling down) */}
      {showButton && (
        <div
          onClick={scrollToTop}
          className="fixed bottom-6 right-6 bg-blue-900 text-white p-4 w-12 h-12 rounded-full flex items-center justify-center cursor-pointer shadow-lg transition-transform hover:scale-105"
          style={{ lineHeight: 0 }}
        >
          <i className="fas fa-arrow-up text-2xl"></i> {/* Arrow Up Icon */}
        </div>
      )}
    </>
  );
};

export default ScrollToTopButton;
