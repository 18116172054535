import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const StyledWrapper = styled.div`
  .card {
    display: flex;
    flex-direction: column;
    height: 180px;
    width: 280px;
    border-radius: 25px;
    background: lightgrey;
    overflow: hidden;
    transition: 100ms ease;
    box-shadow: rgba(0, 0, 0, 0.15) 2px 3px 4px;
  }

  /* ---------- Info section ---------- */

  .info-section {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 75%;
    color: white;
  }

  .left-side {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    z-index: 1;
    padding-left: 18px;
  }

  button {
    display: block;
    border: none;
    background: transparent;
  }

  .weather {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
  }

  .weather div {
    display: flex;
    align-items: center;
  }

  .weather div:nth-child(1) {
    width: 40%;
    height: auto;
  }

  .temperature {
    font-size:  40pt;
    font-weight: 500;
    margin-bottom: 20px;
    margin-left: 15px;
    line-height: 8%;
  }

  .right-side {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
    height: 100%;
    padding-right: 18px;
    z-index: 1;
  }

  .right-side > div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .hour {
    font-size: 16pt;
    line-height: 1em;
  }

  .date {
    font-size: 15px;
  }

  /* ---------- Background ---------- */
  .background-design {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #ec7263;
    overflow: hidden;
  }

  .circle {
    background-color: #efc745;
  }

  .circle:nth-child(1) {
    position: absolute;
    top: -80%;
    right: -50%;
    width: 300px;
    height: 300px;
    opacity: 0.4;
    border-radius: 50%;
  }

  .circle:nth-child(2) {
    position: absolute;
    top: -70%;
    right: -30%;
    width: 210px;
    height: 210px;
    opacity: 0.4;
    border-radius: 50%;
  }

  .circle:nth-child(3) {
    position: absolute;
    top: -35%;
    right: -8%;
    width: 100px;
    height: 100px;
    opacity: 1;
    border-radius: 50%;
  }

  /* ---------- Days section ---------- */
  .days-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 25%;
    background-color: #974859;
    gap: 2px;
    box-shadow: inset 0px 2px 5px #974859;
  }

  .days-section button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: #a75265;
    box-shadow: inset 0px 2px 5px #974859;
    cursor: pointer;
    transition: 100ms ease;
    gap: 5px;
  }

  .days-section button:hover {
    scale: 0.9;
    border-radius: 10px;
  }

  .days-section .day {
    font-size: 10pt;
    font-weight: 500;
    color: white;
    opacity: 0.7;
  }

  .icon-weather-day {
    display: flex;
    align-items: center;
    width: 20px;
    height: 100%;
  }`;

  const Card = () => {
    const [weather, setWeather] = useState([]);
    const API_KEY = '4a635d929a34d649bc9527e64e3ce7bd';
    const city = 'Rajahmundry';
  
    useEffect(() => {
      const fetchWeather = async () => {
        try {
          const response = await axios.get(
            `https://api.openweathermap.org/data/2.5/forecast?q=${city}&units=metric&cnt=32&appid=${API_KEY}`
          );
          const data = response.data;
  
          // Extract weather for the next 4 days (one per day)
          const dailyWeather = data.list.filter((item, index) => index % 8 === 0).slice(0, 4);
  
          const forecast = dailyWeather.map((item) => {
            const date = new Date(item.dt * 1000);
            return {
              city: data.city.name,
              temperature: `${Math.round(item.main.temp)}°`,
              condition: item.weather[0].main,
              icon: item.weather[0].icon,  // Icon code from API
              date: date.toLocaleDateString('en-US', { weekday: 'short' }),
            };
          });
  
          setWeather(forecast);
        } catch (error) {
          console.error('Error fetching weather data:', error);
        }
      };
  
      fetchWeather();
    }, []);
  
    return (
      <StyledWrapper>
        <div className="card">
          <section className="info-section">
            <div className="background-design">
              <div className="circle" />
              <div className="circle" />
              <div className="circle" />
            </div>
  
            {weather.length > 0 && (
              <>
                <div className="left-side">
                  <div className="weather">
                    <div>
                      <img
                        src={`https://openweathermap.org/img/wn/${weather[0].icon}@2x.png`}
                        alt={weather[0].condition}
                      />
                    </div>
                    <div>{weather[0].condition}</div>
                  </div>
                  <div className="temperature">{weather[0].temperature}</div>
                </div>
                <div className="right-side">
                  <div>
                    <div className="hour">{new Date().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}</div>
                    <div className="date">{weather[0].date}</div>
                  </div>

                  <div className="city">{weather[0].city}</div>
                </div>
              </>
            )}
          </section>
  
          {/* Forecast for the next 4 days */}
          <section className="days-section">
            {weather.map((day, index) => (
              <button key={index}>
                <span className="day">{day.date}</span>
                <img
                  src={`https://openweathermap.org/img/wn/${day.icon}@2x.png`}
                  alt={day.condition}
                  className="w-8 h-8"
                />
              </button>
            ))}
          </section>
        </div>
      </StyledWrapper>
    );
  };
  
  export default Card;
  
